import create from 'zustand'
import camelcaseKeys from 'camelcase-keys-deep'
import {
  compose,
  makePersist,
  makeDevtools,
  immer,
  leading,
} from '../lib/zustand'
import useRequest from '../lib/useRequest'

const userState = (set, get, { leading }) => ({
  user: null,
  token: null,
  isAdmin: () => get().user?.role === 'admin',
  signIn: leading(async ({ email, password }) => {
    const response = await fetch(`/api/users/sign_in`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user: { email, password } }),
    })

    const data = camelcaseKeys(await response.json())

    if (!response.ok) {
      throw data
    }

    set(state => {
      state.user = data.user
      state.token = data.token
    })

    return data
  }),
  signOut: () => {
    set(state => {
      state.user = null
      state.token = null
    })
  },
  signUp: leading(async values => {
    const response = await fetch(`/api/users/sign_up`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user: {
          email: values.email,
          password: values.password,
        },
        customer: {
          name: null,
          short_name: null,
          tax_id: null,
          address: {
            first_line: values.firstLine,
            second_line: values.secondLine,
            postal_code: values.postalCode,
            city: values.city,
            country: values.country,
            contact_phone: values.contactPhone,
            contact_email: values.email,
          },
        },
      }),
    })

    const data = camelcaseKeys(await response.json())

    if (!response.ok) {
      throw data
    }

    set(state => {
      state.user = data.user
      state.token = data.token
    })

    return data
  }),
  fetchCurrentUser: leading(async () => {
    if (!get().user) {
      throw new Error('User is not signed in')
    }

    const response = await fetch(`/api/users/me`, {
      headers: { Authorization: `Bearer ${get().token}` },
    })

    if (!response.ok) {
      throw new Error('User is not signed in')
    }

    const { user } = camelcaseKeys(await response.json())
    set(state => {
      state.user = user
    })

    return user
  }),
})

export const useSignIn = () => {
  const signInAction = useUserState(state => state.signIn)
  return useRequest(({ email, password }) => signInAction({ email, password }))
}

export const useSignUp = () => {
  const signUpAction = useUserState(state => state.signUp)
  return useRequest(
    values => signUpAction(values),
    error => ({ ...error.errors?.user, ...error.errors?.customer })
  )
}

export const useIsSignedIn = () => !!useUserState(state => state.user)

const useUserState = compose(
  create,
  immer,
  leading,
  makeDevtools({ name: 'user' }),
  makePersist({ name: 'user' })
)(userState)

export default useUserState

import React, { useId, forwardRef, memo } from 'react'
import { chakra, useToken } from '@chakra-ui/react'

const SabnerLogo = ({ title = 'Sabner', color = 'white', ...props }, ref) => {
  const id = 'sabner-logo-' + useId()
  const fill = useToken('colors', color)

  return (
    <chakra.svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='auto'
      viewBox='0 0 1612.000000 245.000000'
      preserveAspectRatio='xMidYMid meet'
      aria-labelledby={id}
      ref={ref}
      {...props}
    >
      {title && <title id={id}>{title}</title>}
      <g
        transform='translate(0.000000,245.000000) scale(0.100000,-0.100000)'
        fill={fill}
        stroke='none'
      >
        <path d='M5772 2343 l-222 -106 0 -1119 0 -1118 1230 0 1230 0 0 1000 0 1000 -840 0 -840 0 0 -225 0 -225 610 0 610 0 0 -550 0 -550 -770 0 -770 0 0 1000 c0 550 -3 1000 -8 1000 -4 0 -107 -48 -230 -107z' />
        <path d='M0 1385 l0 -615 1000 0 1000 0 0 -160 0 -160 -1000 0 -1000 0 0 -225 0 -225 1225 0 1225 0 0 615 0 615 -1000 0 -1000 0 0 160 0 160 1000 0 1000 0 0 225 0 225 -1225 0 -1225 0 0 -615z' />
        <path d='M2770 1000 l0 -1000 840 0 840 0 0 225 0 225 -610 0 -610 0 0 550 0 550 770 0 770 0 0 -775 0 -775 230 0 230 0 0 1000 0 1000 -1230 0 -1230 0 0 -1000z' />
        <path d='M8330 1000 l0 -1000 225 0 225 0 0 775 0 775 775 0 775 0 0 -775 0 -775 225 0 225 0 0 1000 0 1000 -1225 0 -1225 0 0 -1000z' />
        <path d='M11110 1775 l0 -225 1225 0 1225 0 0 225 0 225 -1225 0 -1225 0 0 -225z' />
        <path d='M13880 1000 l0 -1000 230 0 230 0 0 775 0 775 890 0 890 0 0 225 0 225 -1120 0 -1120 0 0 -1000z' />
        <path d='M11110 1000 l0 -230 1225 0 1225 0 0 230 0 230 -1225 0 -1225 0 0 -230z' />
        <path d='M11110 225 l0 -225 1225 0 1225 0 0 225 0 225 -1225 0 -1225 0 0 -225z' />
      </g>
    </chakra.svg>
  )
}

const SabnerLogoForwardRef = forwardRef(SabnerLogo)
const SabnerLogoChakra = chakra(SabnerLogoForwardRef, {
  shouldForwardProp: prop => ['color', 'title'].includes(prop),
})
const SabnerLogoMemo = memo(SabnerLogoChakra)

export default SabnerLogoMemo

import React from 'react'
import { createRoot } from 'react-dom/client'
import Home from '../home/Home'

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('home-page')
  if (container) {
    const root = createRoot(container)
    root.render(
      <React.StrictMode>
        <Home />
      </React.StrictMode>
    )
  }
})

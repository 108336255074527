import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

const theme = extendTheme(
  {
    initialColorMode: 'system',
    useSystemColorMode: false,
    fonts: {
      heading: `'Titillium Web', sans-serif`,
      body: `'Titillium Web', sans-serif`,
    },
    colors: {
      primary: {
        50: '#dffbff',
        100: '#bae7f6',
        200: '#94d1ea',
        300: '#6bbae0',
        400: '#43a0d5',
        500: '#2a82bc',
        default: '#2a82bc',
        600: '#1c6d93',
        700: '#0f546a',
        800: '#023843',
        900: '#00171c',
      },
    },
    sizes: {
      navbar: '70px',
      imgForm: '200px',
      imgHeader: '1000px',
    },
    space: {
      navbar: '70px',
    },
    components: {
      Input: {
        defaultProps: {
          variant: 'filled',
        },
      },
      NumberInput: {
        defaultProps: {
          variant: 'filled',
        },
      },
      Textarea: {
        defaultProps: {
          variant: 'filled',
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: 'primary' })
)

export default theme

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export const initI18n = ({ pl, en }) => {
  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: en },
      pl: { translation: pl },
    },
    lng: localStorage.getItem('locale') || document?.documentElement?.lang,
    fallbackLng: 'pl',
    interpolation: {
      escapeValue: false,
    },
  })
}

export default i18n

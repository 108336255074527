import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Flex,
  Stack,
  VStack,
  Heading,
  Link,
  useColorModeValue,
} from '@chakra-ui/react'
import heroBg from '../../assets/images/hero.jpg'
import SabnerLogo from '../components/SabnerLogo'
import Actions from './Actions'
import SideMenu from './SideMenu'

const Hero = () => {
  const { t } = useTranslation()

  return (
    <Flex
      w='full'
      h='100vh'
      position='relative'
      backgroundImage={`url(${heroBg})`}
      backgroundSize='cover'
      backgroundPosition='center center'
    >
      <VStack
        w='full'
        justify='center'
        pr={{ base: 6, sm: 8 }}
        pl={{ base: 6, sm: 10, md: 16 }}
        bgGradient={useColorModeValue(
          'linear(to-r, blackAlpha.600, transparent)',
          'linear(to-r, blackAlpha.800, blackAlpha.600)'
        )}
        backdropFilter={useColorModeValue(
          'blur(2.5px) brightness(0.8)',
          'blur(2.5px)'
        )}
      >
        <Stack w='full' maxW='5xl' align='flex-start' spacing={8}>
          <Flex
            w='full'
            mb={{ base: 4, sm: 8 }}
            justifyContent={{ base: 'center', sm: 'flex-start' }}
          >
            <Link
              href='/customer-area/orders'
              w={{ base: 'full', sm: undefined }}
            >
              <SabnerLogo
                w={{ base: 'full', sm: '300px', md: '350px' }}
                filter='drop-shadow(0px 2px 6px black)'
                color={useColorModeValue('white', 'whiteAlpha.800')}
              />
            </Link>
          </Flex>
          <Stack
            pt={{ base: 8, sm: 0 }}
            pr={{ base: 0, sm: 8, md: 0 }}
            spacing={{ base: 4, sm: 2 }}
          >
            <HeaderText
              as='h1'
              fontSize={{ base: '4xl', sm: '3xl', md: '4xl' }}
            >
              {t('header')}
            </HeaderText>
            <HeaderText as='h2' fontSize={{ base: '2xl', sm: 'xl', md: '2xl' }}>
              {t('subHeader')}
            </HeaderText>
          </Stack>
          <Stack
            w={{ base: 'full', sm: 'unset' }}
            direction={{ base: 'column', sm: 'row' }}
            spacing={{ base: 6, sm: 4, md: 8 }}
          >
            <Actions />
          </Stack>
        </Stack>
      </VStack>
      <SideMenu />
    </Flex>
  )
}

const HeaderText = props => {
  return (
    <Heading
      color={useColorModeValue('white', 'whiteAlpha.800')}
      fontWeight={700}
      lineHeight={1.2}
      textAlign={{ base: 'center', sm: 'unset' }}
      textShadow='0px 2px 6px black'
      {...props}
    />
  )
}

export default Hero

import { useState, useCallback, useRef } from 'react'

const defaultHandleError = error => error

const useRequest = (request, handleError = defaultHandleError) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false)
  const requestRef = useRef(request)
  const handleErrorRef = useRef(handleError)

  requestRef.current = request
  handleErrorRef.current = handleError

  const doRequest = useCallback(async (...args) => {
    setLoading(true)
    setErrors(false)

    try {
      return await requestRef.current(...args)
    } catch (err) {
      setErrors(handleErrorRef.current(err))
      return false
    } finally {
      setLoading(false)
    }
  }, [])

  const handler = [doRequest, { loading, errors }]
  handler.request = doRequest
  handler.loading = loading
  handler.errors = errors

  return handler
}

export default useRequest

import React, { useRef, useContext, createContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  chakra,
  VStack,
  Portal,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { GB, PL } from 'country-flag-icons/react/3x2'

const GBFlag = chakra(GB)
const PLFlag = chakra(PL)

const FLAGS = { pl: PLFlag, en: GBFlag }
const TRANSLATIONS = { pl: 'Polski', en: 'English' }

const LanguageSelectorContext = createContext()

const LanguageSelector = ({ children, ...props }) => {
  const initialFocusRef = useRef()
  const { i18n } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const buttonsColorScheme = useColorModeValue(undefined, 'gray')

  const handleOnClick = locale => e => {
    e.preventDefault()
    i18n.changeLanguage(locale)
    localStorage.setItem('locale', locale)
    onClose()
  }

  return (
    <LanguageSelectorContext.Provider value={onOpen}>
      <Popover
        initialFocusRef={initialFocusRef}
        isOpen={isOpen}
        onClose={onClose}
        {...props}
      >
        {children}
        <Portal>
          <PopoverContent w='none'>
            <PopoverArrow />
            <PopoverBody>
              <VStack align='stretch'>
                {Object.entries(FLAGS).map(([locale, Flag], index) => (
                  <Button
                    key={locale}
                    justifyContent='flex-start'
                    onClick={handleOnClick(locale)}
                    textTransform='uppercase'
                    colorScheme={buttonsColorScheme}
                    {...(index === 0 && { ref: initialFocusRef })}
                  >
                    <Flag w={5} mr={4} title={TRANSLATIONS[locale]} />
                    {locale}
                  </Button>
                ))}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </LanguageSelectorContext.Provider>
  )
}

const LanguageSelectorButton = ({ onClick, ...props }) => {
  const onOpen = useContext(LanguageSelectorContext)

  return (
    <PopoverTrigger>
      <Button
        minW='unset'
        px={0}
        bg='transparent'
        onClick={e => (onClick ? onClick(e, { onOpen }) : onOpen())}
        {...props}
      />
    </PopoverTrigger>
  )
}

const LanguageSelectorActiveFlag = props => {
  const { i18n } = useTranslation()
  const ActiveFlag = FLAGS[i18n.language] || FLAGS.pl

  return (
    <ActiveFlag
      title={TRANSLATIONS[i18n.language] || TRANSLATIONS.pl}
      {...props}
    />
  )
}

LanguageSelector.Button = LanguageSelectorButton
LanguageSelector.ActiveFlag = LanguageSelectorActiveFlag

export default LanguageSelector

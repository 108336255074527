import React from 'react'
import pl from '../../../config/locales/pl/home.json'
import en from '../../../config/locales/en/home.json'
import { initI18n } from '../configs/i18n'
import ChakraProvider from '../components/ChakraProvider'
import Hero from './Hero'

initI18n({ pl, en })

const Home = () => {
  return (
    <ChakraProvider>
      <Hero />
    </ChakraProvider>
  )
}

export default Home

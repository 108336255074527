import { persist, devtools } from 'zustand/middleware'
import produce from 'immer'
import { createSelector } from 'reselect'

export const compose =
  (...functions) =>
  x =>
    functions.reduceRight((acc, fn) => fn(acc), x)

export const makePersist =
  (options = {}) =>
  storeConfig =>
    persist(storeConfig, options)

export const makeDevtools =
  (options = {}) =>
  storeConfig =>
    devtools(storeConfig, options)

export const immer = storeConfig => (set, get, api) =>
  storeConfig(
    (fnOrNewState, ...args) =>
      set(
        typeof fnOrNewState === 'function'
          ? produce(fnOrNewState)
          : fnOrNewState,
        ...args
      ),
    get,
    api
  )

export const reselect = storeConfig => (set, get, api) => {
  api.selector = (...selectors) => {
    const makeSelector = () => createSelector(...selectors)
    const selector = makeSelector()
    const select = (...args) => selector(get(), ...args)

    select.selector = selector
    select.createSelector = makeSelector

    return select
  }

  return storeConfig(set, get, api)
}

export const leading = storeConfig => (set, get, api) => {
  api.leading = fn => {
    let promise = null

    return async (...args) => {
      if (promise !== null) {
        return promise
      }

      try {
        promise = fn(...args)
        return await promise
      } finally {
        promise = null
      }
    }
  }

  return storeConfig(set, get, api)
}

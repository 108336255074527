import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, useBreakpointValue, useColorModeValue } from '@chakra-ui/react'
import useUser, { useIsSignedIn } from '../hooks/useUser'

const useHasDraftOrders = () => {
  const json = localStorage.getItem('draft-orders')
  return useMemo(() => !!JSON.parse(json)?.state?.currentId, [json])
}

const Actions = () => {
  const user = useUser(state => state.user)

  if (user?.role === 'admin') {
    return <AdminActions />
  }

  return (
    <>
      <OrderActions />
      <UserActions />
    </>
  )
}

const AdminActions = () => {
  const { t } = useTranslation()

  return (
    <>
      <CallToAction colorScheme='primary' href='/dashboard'>
        {t('actions.dashboard')}
      </CallToAction>
      <CallToAction colorScheme='green' href='/customer-area'>
        {t('actions.customerArea')}
      </CallToAction>
    </>
  )
}

const OrderActions = () => {
  const { t } = useTranslation()
  const hasDraftOrders = useHasDraftOrders()

  return hasDraftOrders ? (
    <CallToAction colorScheme='primary' href='/customer-area/orders/edit'>
      {t('actions.editOrder')}
    </CallToAction>
  ) : (
    <CallToAction colorScheme='primary' href='/customer-area/orders/new'>
      {t('actions.newOrder')}
    </CallToAction>
  )
}

const UserActions = () => {
  const { t } = useTranslation()
  const isSignedIn = useIsSignedIn()

  return isSignedIn ? (
    <CallToAction colorScheme='green' href='/customer-area/orders'>
      {t('actions.orders')}
    </CallToAction>
  ) : (
    <CallToAction colorScheme='green' href='/customer-area/sign-in'>
      {t('actions.signIn')}
    </CallToAction>
  )
}

const CallToAction = ({ colorScheme, ...props }) => {
  return (
    <Button
      as='a'
      size={useBreakpointValue({ base: 'lg', sm: 'md', md: 'lg' })}
      rounded='full'
      color={useColorModeValue('white', 'gray.300')}
      bg={useColorModeValue(`${colorScheme}.400`, `${colorScheme}.700`)}
      _hover={{
        bg: useColorModeValue(`${colorScheme}.500`, `${colorScheme}.800`),
      }}
      _active={{
        bg: useColorModeValue(`${colorScheme}.600`, `${colorScheme}.800`),
      }}
      {...props}
    />
  )
}

export default Actions

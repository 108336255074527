import React from 'react'
import {
  Stack,
  IconButton,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import LanguageSelector from '../components/LanguageSelector'
import Icon from '../components/FontAwesomeIcon'

const SideMenu = () => {
  return (
    <Stack
      spacing={6}
      direction={{ base: 'row', sm: 'column' }}
      position='absolute'
      top={{ base: 6, sm: '50%' }}
      right={6}
      transform={{ base: undefined, sm: 'translateY(-50%)' }}
    >
      <LanguageSelector placement='left' gutter={16}>
        <LanguageSelector.Button variant='link'>
          <LanguageSelector.ActiveFlag
            w={6}
            filter={useColorModeValue(undefined, 'brightness(0.8)')}
          />
        </LanguageSelector.Button>
      </LanguageSelector>
      <ColorModeSelector />
    </Stack>
  )
}

const ColorModeSelector = props => {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <IconButton
      variant='link'
      minW='unset'
      color={useColorModeValue('white', 'gray.300')}
      icon={<Icon icon={colorMode === 'dark' ? faSun : faMoon} boxSize={5} />}
      onClick={toggleColorMode}
      _active={{ color: 'whiteAlpha.700' }}
      {...props}
    />
  )
}

export default SideMenu
